<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :items="items"
    :total-registros="totalRegistros"
    :paginacao.sync="pagination"
    :hide-headers="$vuetify.breakpoint.smAndDown"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template
      slot="items"
      slot-scope="{ item }"
    >
      <tr>
        <td
          class="justify-left btnEditar cursorPointer"
          @click="editar(item.item)"
        >
          {{ item.item.nomeTipoEvento }}
          <v-icon small>
            mdi-pencil
          </v-icon>
        </td>
        <td v-if="!idEmpresaCliente || idEmpresaCliente < 0">
          <span v-if="item.item.EmpresaCliente !== null">
            {{ item.item.EmpresaCliente.nome }}
          </span>
        </td>
      </tr>
    </template>
  </crud-list>
</template>

<script>

  import TipoEventoService from '@/services/TipoEventoService'
  import { mapState } from 'vuex'
  import colunaEmpresaCliente from '@/utils/EmpresaClienteUtils'

  export default {
    data: () => ({
      totalRegistros: 0,
      pagination: {},

      items: [],
      loading: false,
      itemRemover: {}
    }),
    computed: {
      ...mapState(['idEmpresaCliente']),
      tituloPesquisa: function () {
        return this.$i18n.t('TipoEventosForm.tituloPesquisa')
      },
      headers: function () {
        const retorno = [
          {
            text: this.$i18n.t('TipoEventosForm.nomeTipoEvento'),
            value: 'nomeTipoEvento',
            sortable: false
          }
        ]

        if (!this.idEmpresaCliente || this.idEmpresaCliente < 0) {
          retorno.push(colunaEmpresaCliente(this.$i18n.t('EmpresaClientesForm.tituloPagina')))
        }

        return retorno
      }
    },
    watch: {
      idEmpresaCliente (val) {
        if(val !== 0){
          this.buscar()
        }
      }
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', true)
      this.$root._events.pesquisa = []
      this.$root.$on('pesquisa', this.efetuarPesquisa)
    },
    methods: {
      efetuarPesquisa (pesquisa) {
        this.loading = true
        const param = {
          idEmpresaCliente: this.idEmpresaCliente,
          nomeTipoEvento: pesquisa
        }
        TipoEventoService.findByParam(param, this.pagination)
          .then((tiposEvento) => {
            if (tiposEvento && tiposEvento.data && tiposEvento.data.rows) {
              this.items = tiposEvento.data.rows
            }
            if (tiposEvento && tiposEvento.data) {
              this.totalRegistros = tiposEvento.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro ao buscar os tipos de evento cadastrados: \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      buscar () {
        if(!this.idEmpresaCliente || this.idEmpresaCliente === 0){
          return
        }
        this.loading = true
        TipoEventoService.findAll(this.pagination, this.idEmpresaCliente)
          .then((tiposEvento) => {
            if (tiposEvento && tiposEvento.data && tiposEvento.data.rows) {
              this.items = tiposEvento.data.rows
            }
            if (tiposEvento && tiposEvento.data) {
              this.totalRegistros = tiposEvento.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro ao buscar os tipos de evento cadastrados: \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      novo () {
        this.$router.push({
          name: 'Cadastro de Tipo de Evento',
          params: null
        })
      },
      editar (item) {
        if (item && item.id) {
          const url = '/tipoEventoForm/' + item.id
          this.$router.push(url)
        }
      }
    }
  }
</script>

<style>
</style>
