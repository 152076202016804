import Api from '@/services/Api'

export default {
  findAll (pagination, idEmpresaCliente) {
    const param = {
      idEmpresaCliente, pagination
    }
    return Api().post('tipoEventos', param)
  },
  insert (tipoEvento) {
    return Api().post('tipoEvento', tipoEvento)
  },
  update (tipoEvento) {
    return Api().put('tipoEvento', tipoEvento)
  },
  delete (tipoEvento) {
    return Api().delete(`tipoEvento?id=${tipoEvento.id}`, tipoEvento)
  },
  findById (tipoEvento) {
    const url = `tipoEvento/${tipoEvento.id}`
    return Api().get(url)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('tipoEventosParam', param)
  }
}
